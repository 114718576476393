import '@unocss/reset/tailwind.css'
import 'virtual:uno.css'
import 'virtual:unocss-devtools'
import '~/global.css'
import 'notyf/notyf.min.css'

import { hydrate } from 'svelte'
import { createInertiaApp, router } from 'inertiax-svelte'
import axios from 'axios'
import { Notyf } from 'notyf';
import resolve from '../resolve'

const csrfToken = document.querySelector('meta[name=csrf-token]').content
axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken

window.notyf = new Notyf({
  position: {
    x: 'center',
    y: 'bottom'
  },
  types: [
    {
      type: 'success',
      background: 'linear-gradient(0deg, #006400 0%, #008000 50%, #228B22 100%)',
      icon: false,
      duration: 3500
    },
    {
      type: 'error',
      background: 'red',
      icon: false,
      duration: 3500
    }
  ],
  duration: 3500
})

// notyf.success("Welcome to Shitcoin Society!")

createInertiaApp({ 
  resolve,
  setup({ el, App, props }) {
    hydrate(App, { target: el, props })
  },
})

window.addEventListener('message', async function(event) {
  if (event.data == 'logged_in') {
    router.reload({preserveURL: false})
  }
})
